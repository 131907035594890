<!--
 * @Author: your name
 * @Date: 2021-01-28 19:42:51
 * @LastEditTime: 2021-01-29 14:37:53
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: /azg/src/views/newIndex/components/FromWorld.vue
-->
<template>
  <div class="wap_companty_con">
    <div
      v-for="(i, index) in $t('WapIndexIntr1.wappictures')"
      :key="index"
      class="con_item"
      @click="goCompany(i.name)"
    >
      <img :src="i.img" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  methods: {
    goCompany(name) {
      this.$router.push(`/azabord?prev=${name}`);
    }
  }
};
</script>
<style scoped lang="less">
.wap_companty_con {
  // display: flex;
  // justify-content: space-between;
  // align-items: center;
  // margin: calc(100vw * 2.4 / 37.5) calc(100vw * 2.2 / 37.5);
  // padding: 0 calc(100vw * 2.2 / 37.5);
  display: flex;
  margin-top: 56px;
  .con_item {
    // margin-top: calc(100vw * 2.4 / 37.5);
    // background: #c4c4c4;
    width: 100%;
    // margin-right: calc(100vw * 3 / 192);
    // height: calc(100vw * 22 / 37.5);
    cursor: pointer;
    // flex: auto;
    img {
      display: block;
      width: 100%;
      height: calc(100vw * 8.1 / 37.5);
    }
  }
}
</style>
